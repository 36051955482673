import React from 'react'
import { Box } from '@xstyled/styled-components'

export const Container = ({ children, ...props }) => {
  return (
    <Box maxWidth={{ lg: '1200px' }} mx={{ xs: 4, sm: 4, md: 4, lg: 'auto', xl: 'auto' }} {...props}>
      {children}
    </Box>
  )
}

export const Row = ({ children, ...props }) => {
  return (
    <Box row mx={-4} {...props}>
      {children}
    </Box>
  )
}

export const Col = ({ children, ...props }) => {
  return (
    <Box col={{ sm: 1 / 6, md: 1 / 8, lg: 1 / 12 }} px={4} {...props}>
      {children}
    </Box>
  )
}
