import React from 'react'
import { Link } from 'gatsby'
import { Box } from '@xstyled/styled-components'
import { Row, Col, Container } from './grid'
import Logo from './logo'
import Nav from './nav'

const Header = () => (
  <Box bg="white" fontFamily="MTTMilano-Black">
    <Container>
      <Row height="103px" alignItems="center">
        <Col col={{ sm: 3 / 6, md: 2 / 8, lg: 5 / 24 }}>
          <Link to="/">
            <Logo />
          </Link>
        </Col>
        <Col col={{ sm: 3 / 6, md: 6 / 8, lg: 19 / 24 }}>
          <Nav />
        </Col>
      </Row>
    </Container>
  </Box>
)

export default Header
