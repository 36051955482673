/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider, Box } from '@xstyled/styled-components'
import Header from './header'
import Footer from './footer'
import { theme, GlobalStyle } from '../theme/theme'

import '../theme/fonts.css'
import CookieBanner from './cookie-banner'


const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Box flexDirection='row'>
        <Header />
        {children}
        <Footer>© {new Date().getFullYear()}, Horseplay</Footer>
      </Box>
      <CookieBanner />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
