import React from 'react'
import logo from '../images/logo.svg'
import { breakpoints } from '@xstyled/system'
import styled, { css } from '@xstyled/styled-components'

const StyledLogo = styled.img(
  breakpoints({
    xs: css`
      width: 155px;
    `,
    md: css`
      width: 129px;
    `,
    lg: css`
      width: 177px;
    `,
  })
)

const Logo = () => {
  return (
    <StyledLogo
      src={logo}
      alt="Horseplay Ventures"
    />
  )
}

export default Logo
