import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import styled, { css } from '@xstyled/styled-components'
import { breakpoints } from '@xstyled/system'

export const StyledLink = styled(GatsbyLink)`
  color: body;
  text-decoration: none;
  font-family: heading;
  letter-spacing: 1px;

  &:hover {
    border-bottom: 3px solid;
    border-bottom-color: secondary;
  }

  &.active {
    color: primary;
    border-bottom: 3px solid;
    border-bottom-color: secondary;
  }

  ${breakpoints({
    xs: css`
      font-size: 1.2rem;
    `,
    sm: css`
      font-size: 0.8rem;
    `,
    md: css`
      font-size: 0.8rem;
    `,
    lg: css`
      font-size: 1.2rem;
    `,
  })}
`

export const StyledAnchor = styled.a`
  color: body;
  text-decoration: none;
  font-family: heading;
  letter-spacing: 1px;

  &:hover {
    border-bottom: 3px solid;
    border-bottom-color: secondary;
  }

  &.active {
    color: primary;
    border-bottom: 3px solid;
    border-bottom-color: secondary;
  }

  ${breakpoints({
    xs: css`
      font-size: 1.2rem;
    `,
    sm: css`
      font-size: 0.8rem;
    `,
    md: css`
      font-size: 0.8rem;
    `,
    lg: css`
      font-size: 1.2rem;
    `,
  })}
`

const Link = ({children, ...props}) => <StyledLink activeClassName="active" {...props}>{children}</StyledLink>

export default Link
