import React from 'react'
import { Box } from '@xstyled/styled-components'
import { Row, Col, Container } from './grid'
import { Link } from 'gatsby'
import styled, { css, down } from '@xstyled/styled-components'
import linkedin from '../images/linkedin.svg'
import twitter from '../images/twitter.svg'
import Text from './text'
import { system } from '@xstyled/system'

const FooterBox = styled(Box)`
  background-color: primary;
  line-height: 1.7em;

  ${down(
    'md',
    css`
      padding-top: 3;
    `
  )}
`

const FooterText = styled(Text)`
  color: white;
  font-size: 1em;
  line-height: 1.7em;
  margin: 0;
  ${system}

  ${down(
    'lg',
    css`
      font-size: 0.75em;
    `
  )}
`

const FooterCol = styled(Col)`
  ${down(
    'md',
    css`
      margin-bottom: 3;
      text-align: center;
    `
  )}
`

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;

  ${down(
    'lg',
    css`
      font-size: 0.75em;
    `
  )}

  &:hover {
    color: body;
  }
`

const StyledAnchor = styled.a`
  color: white;
  text-decoration: none;

  ${down(
    'lg',
    css`
      font-size: 0.75em;
    `
  )}

  &:hover {
    color: body;
  }
`

const Footer = () => (
  <FooterBox position="relative">
    <Container>
      <Row minHeight="166px" alignItems="center">
        <FooterCol
          col={{ xs: 1, sm: 1, md: 3 / 8, lg: 4 / 12 }}
          order={{ xs: 4, md: 1 }}
        >
          <FooterText mb={4}>Horseplay Ventures</FooterText>

          <FooterText>Born in Hoxton, London</FooterText>
          <FooterText>
            <StyledAnchor href="mailto:comeplay@horseplay.ventures">
              comeplay@horseplay.ventures
            </StyledAnchor>
          </FooterText>
        </FooterCol>
        <FooterCol col={{ xs: 1, sm: 1, md: 2 / 8, lg: 3 / 12 }} order={2}>
          <Box>
            <StyledLink to="/about-us">About Us</StyledLink>
          </Box>
          <Box>
            <StyledLink to="/startup-tools">Free Startup Tools</StyledLink>
          </Box>
          <Box>
            <StyledLink to="/our-companies">Our Companies</StyledLink>
          </Box>
          <Box>
            <StyledAnchor
              href="https://anchor.fm/pitch-deck"
              target="_blank"
              rel="noopener noreferrer"
            >
              Podcast - Pitch Deck
            </StyledAnchor>
          </Box>
        </FooterCol>
        <FooterCol
          col={{ xs: 1, sm: 1, md: 2 / 8, lg: 2 / 12 }}
          order={{ xs: 1, md: 3 }}
        >
          <Box mx={1} display="inline-block">
            <a
              href="https://www.linkedin.com/company/horseplay-ventures"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedin} alt="LinkedIn" width="44px" height="44px" />
            </a>
          </Box>
          <Box mx={1} display="inline-block">
            <a
              href="https://twitter.com/pitchdeckpod"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="Twitter" width="44px" height="44px" />
            </a>
          </Box>
        </FooterCol>
        <FooterCol
          col={{ xs: 1, sm: 1, md: 1 / 8, lg: 3 / 12 }}
          order={{ xs: 3, md: 4 }}
        ></FooterCol>
      </Row>
    </Container>
    <Box position="absolute" bottom={5} right={5}>
      <small>
        <FooterText>All Rights Reserved Horseplay Ventures</FooterText>
      </small>
    </Box>
  </FooterBox>
)

export default Footer
