import React from 'react'
import { breakpoints } from '@xstyled/system'
import styled, { css, Box } from '@xstyled/styled-components'
import { Strong } from './text'
import Button from './button'
import Link from './link'
import { useCookies } from 'react-cookie'

const CookieBox = styled.box(
  breakpoints({
    xs: css`
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 115px;
    `,
    md: css`
      height: 95px;
    `,
    lg: css`
      height: 80px;
    `,
  })
)

const CookieLink = styled(Link)(
  css`
    text-transform: none;
    color: white;
    font-size: 20px;
    border-bottom: 3px solid;
    border-bottom-color: secondary;
    &:hover {
      color: secondary;
    }
    &.active {
      color: white;
    }
  `
)

const CookieBanner = () => {
  const [cookies, setCookie] = useCookies(['accepted-cookies'])
  const { 'accepted-cookies': acceptedCookie = true } = cookies

  return (
    !acceptedCookie && (
      <CookieBox
        bg="primary"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box mr={4}>
          <Strong color="white">
            We use cookies to ensure that we give you the best experience on our
            website. <CookieLink to="/cookies-policy">Click here</CookieLink>{' '}
            for more information.{' '}
          </Strong>
        </Box>

        <Button
          size="small"
          onClick={() => setCookie('accepted-cookies', true)}
        >
          Got It
        </Button>
      </CookieBox>
    )
  )
}

export default CookieBanner
