import React from 'react'
import { createSystemComponent } from '@xstyled/system'

export const filterComponent = (Component, omitProps = []) => {
  const unallowedProps = {
    meta: {
      props: ['connected', ...omitProps]
    }
  }
  return createSystemComponent(React, Component, unallowedProps)
}
