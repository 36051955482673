import React, { useState, useEffect } from 'react'
import Link, { StyledLink, StyledAnchor } from './link'
import { Box, useDown } from '@xstyled/styled-components'
import { system } from '@xstyled/system'
import styled, { css } from '@xstyled/styled-components'
import hamburgerIcon from '../images/hamburger.svg'
import closeIcon from '../images/close.svg'
import { filterComponent } from '../utilities/filterComponent'

const StyledNav = styled.box(
  () =>
    css`
      ${system}
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
    `
)

const StyledMobileNav = styled(filterComponent(Box, ['isOpen', 'isExpanding']))(
  ({ isOpen, isExpanding }) =>
    css`
      ${isOpen
    ? css`
            display: flex;
          `
    : css`
            display: none;
          `}
      position: absolute;
      ${isExpanding
    ? css`
            height: 100%;
          `
    : css`
            height: 0;
          `}
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: secondary;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all 0.35s ease;

      ${StyledLink} {
        margin-top: 24px;
        margin-bottom: 24px;

        &.active {
          color: white;
          border-bottom: 3px solid;
          border-bottom-color: primary;
        }
      }

      ${StyledAnchor} {
        margin-top: 24px;
        margin-bottom: 24px;
      }
    `
)

const CloseIcon = styled.img(
  () => css`
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
  `
)

const Nav = () => {
  return (
    <NavWrapper>
      <Link to="/">Home</Link>
      <Link to="/our-companies/">Our Companies</Link>
      <Link to="/investor/">Investor?</Link>
      <Link to="/startup/">Startup?</Link>
      <Link to="/startup-tools/">Startup Tools</Link>
      <StyledAnchor target="_blank" href='https://anchor.fm/pitch-deck'>Podcast</StyledAnchor>
    </NavWrapper>
  )
}

const NavWrapper = ({ children }) => {
  const isMobile = useDown('md')
  const [isOpen, setIsOpen] = useState(false)
  const [isExpanding, setIsExpanding] = useState(false)

  useEffect(() => {
    if (isOpen && !isExpanding) {
      setIsExpanding(true)
    }

    if (isExpanding && isOpen) {
      setTimeout(() => {
        setIsExpanding(false)
      }, 500)
    }
  }, [isOpen, isExpanding])

  return isMobile ? (
    <StyledNav textAlign="right">
      <Box ml="auto" onClick={() => setIsOpen(true)}>
        <Hamburger />
      </Box>

      <StyledMobileNav isOpen={isOpen} isExpanding={isExpanding}>
        <>
          <Box onClick={() => setIsOpen(false)}>
            <Close />
          </Box>
          {children}
        </>
      </StyledMobileNav>
    </StyledNav>
  ) : (
    <StyledNav>{children}</StyledNav>
  )
}

const Hamburger = () => {
  return <img src={hamburgerIcon} alt="Toggle navigation" />
}

const Close = ({ onClick, ...props }) => {
  return <CloseIcon src={closeIcon} alt="Toggle navigation" {...props} />
}

export default Nav
