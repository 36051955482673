import React from 'react'

import styled, { css } from '@xstyled/styled-components'
import { breakpoints, system } from '@xstyled/system'
const sizes = () => {
  return {
    small: css`
      ${breakpoints({
    xs: css`
          padding-top: 1;
          padding-bottom: 1;
        `,
    md: css`
          padding-top: 2;
          padding-bottom: 2;
          min-width: 120px;
        `,
    lg: css`
          padding-top: 2;
          padding-bottom: 2;
          min-width: 180px;
        `,
  })}
    `,
    default: css`
      ${breakpoints({
    xs: css`
          padding-top: 20px;
          padding-bottom: 20px;
          min-width: 300px;
        `,
    md: css`
          min-width: 200px;
        `,
    lg: css`
          min-width: 300px;
        `,
  })}
    `,
  }
}
const StyledButton = styled.button(
  ({ size }) =>
    css`
      ${system}
      ${sizes()[size]}
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-family: heavy;
      cursor: pointer;
      background-color: transparent;
      border: 0;
      color: body;
      text-decoration: none;
      text-transform: uppercase;
      position: relative;
      border-color: body;
      border-width: 3px;
      border-style: solid;
      font-weight: bolder;

      &:disabled {
        cursor: not-allowed;
        color: #BCBEC0;
        border-color: #BCBEC0;
      }

      &:hover&:not(:focus)&:not(:disabled) {
        border-color: body;
        background-color: secondary;
      }

      &:before {
        content: '';
        border-color: secondary;
        background-color: secondary;
        border-width: 3px;
        border-style: solid;
        height: 101%;
        position: absolute;
        z-index: -1;
        right: 7px;
        bottom: 8px;
        width: 101%;
      }

      &:disabled&:before {
        background-color: inputDisabled;
        border-color: inputDisabled;
      }

      &:not(:disabled)&:active&:before,
      &:not(:disabled)&:focus&:before,
      &:not(:disabled)&:hover&:before {
        border-color: body;
        background-color: transparent;
      }
    `
)

const Button = ({ size = 'default', children, ...props }) => (
  <StyledButton size={size} {...props}>
    {children}
  </StyledButton>
)

export default Button
