import React from 'react'
import styled, { css } from '@xstyled/styled-components'
import { system } from '@xstyled/system'

const StyledText = styled.p(
  () => css`
    ${system}
    cursor: default;
    line-height: 1.3em;
  `
)

const StyledStrong = styled.strong(
  () => css`
    ${system}
    cursor: default;
    font-size: 1.25em;
    font-weight: bold;
  `
)

const StyledEmphasis = styled.strong(
  () => css`
    ${system}
    cursor: default;
    font-family: heading;
  `
)

export const Text = ({ children, color = 'body', fontSize='1.25rem', ...props }) => (
  <StyledText color={color} fontSize={fontSize} {...props}>{children}</StyledText>
)

export const Strong = ({ children, color = 'body', ...props }) => (
  <StyledStrong color={color} {...props}>{children}</StyledStrong>
)

export const Emphasis = ({ children, color = 'body', ...props }) => (
  <StyledEmphasis color={color} {...props}>{children}</StyledEmphasis>
)

const StyledH3 = styled(Text)`
  font-size: 1.7em;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
`

export const H3 = ( {children, ...props }) => (
  <StyledH3 {...props}>{children}</StyledH3>
)

export default Text
