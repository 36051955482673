import { createGlobalStyle, css } from '@xstyled/styled-components'
import { normalize } from 'polished'

export const theme = {
  colors: {
    primary: '#00A378',
    secondary: '#FFB5BE',
    body: '#30323D',
    yellow: '#FFE882',
    inputGray: '#EBF1F0',
    inputDisabled: '#E6E7E8',
  },
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  space: [0, 4, 8, 16, 24, 48, 96, 144, 192, 240],
  fonts: {
    heading: 'MixtaDidoneAlt-Black',
    body: 'MTTMilano',
    heavy: 'MTTMilano-Black',
    bold: 'MTTMilano-Bold',
  },
}

export const GlobalStyle = createGlobalStyle(
  () => css`
    ${normalize()};
    body {
      margin: 0;
      font-family: 'MTTMilano', -apple-system, BlinkMacSystemFont, Segoe UI,
        Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
    }

    html {
      box-sizing: border-box;
      -ms-overflow-style: scrollbar;
    }

    * {
      &,
      &::before,
      &::after {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    a,
    a:link,
    a:visited,
    a:hover,
    a:active {
      text-decoration: none;
    }
  `
)
